import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk' // I still add this for my reference so I know thunk middleware is added
 
const store = configureStore({
    reducer: {},
    preloadedState: {},
    middleware: [thunk],
})
 
export default store
// inject the provider in the index.js file for an entry point in react. 