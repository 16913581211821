import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container } from 'react-bootstrap'

const Header = () => {
  return (
    <header className='blue-glassmorphism'>
        <Navbar className='shadow' bg="blue" variant="dark" expand="lg" collapseOnSelect>
          <Container>
            <LinkContainer to='/'>
              <Navbar.Brand className='font-weight-light textc drop-shadow-sm'>𐌠ᒪᒪ-ⴹᗅGᒪⴹGⴹᗅᒥ</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <LinkContainer to='/cart'>
                  <Nav.Link className='textc'><i className='shadow fas fa-shopping-cart'></i>Cart</Nav.Link>
                </LinkContainer>
                <LinkContainer to='/login'>
                  <Nav.Link className='textc'><i className='shadow fas fa-user'></i>Sign-in</Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <LinkContainer to='/'>
          <Navbar.Brand className='text-center textb py-2 px-4 font-weight-light'>A Fᴜᴛᴜʀᴇ Tᴏ Cʜᴏᴏsᴇ Fʀᴏᴍ</Navbar.Brand>
        </LinkContainer>
    </header>
  )
}
export default Header