import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Row, Col, Image, ListGroup, Card, Button } from 'react-bootstrap'
import Rating from '../components/Rating'
// import products from '../products'
import axios from 'axios'

const ProductScreen = () => {
  // REFERENCE: https://stackoverflow.com/a/70293990
  // https://stackoverflow.com/questions/70290770/react-typeerror-cannot-read-properties-of-undefined-reading-params
  // https://stackoverflow.com/questions/64782949/how-to-pass-params-into-link-using-react-router-v6
  // https://medium.com/@manishsundriyal/whats-new-in-react-router-v6-20eefe665be9#:~:text=In%20v6%20all%20the%20routes%20match%20exactly%20by%20default.
  // const { id } = useParams()
  // const product = products.find((p) => String(p._id) === id)
  // if (!product) return null
  const params = useParams()
  // const product = products.find((p) => p._id === (id))
  // const product = products.find((p) => String(p._id) === id)
  const [product, setProduct] = useState({})

  useEffect(() => {
    // await axios.get('/api/products')
    const fetchProduct = async () => {
      const { data } = await axios.get(`/api/products/${params.id}`)
      // const product = products.find((p) => String(p._id) === id);

      setProduct(data)
    }

    fetchProduct()
  }, [params])

  return (
    <>
      <Link className='btn btn-light my-3 white-glassmorphism textc' to='/'>Go Back</Link>
      <Row>
        <Col md={6}>
          <Image src={product.image} alt={product.name} fluid />
        </Col>
        <Col md={3}>
          <ListGroup variant='flush' className='white-glassmorphism text-white'>
            <ListGroup.Item className='white-glassmorphism text-white'>
              <h3>{product.name}</h3>
            </ListGroup.Item>
            <ListGroup.Item className='white-glassmorphism text-white'>
              <Rating value={product.rating} text={`${product.numReviews} reviews`} />
            </ListGroup.Item>
            <ListGroup.Item className='white-glassmorphism text-white'>
              Price: ${product.price}
            </ListGroup.Item>
            <ListGroup.Item className='white-glassmorphism text-white'>
              Description: {product.description}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={3}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <Row>
                  <Col className='white-glassmorphism'>
                    Price: 
                  </Col>
                  <Col>
                    <strong>${product.price}</strong>
                  </Col>
                </Row>
              </ListGroup.Item>
              
              <ListGroup.Item>
                <Row>
                  <Col>
                    Status: 
                  </Col>
                  <Col>
                    {product.countInStock > 0 ? 'In Stock' : 'Out Of Stock'}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                {/* <Button className='btn-block' type='button' disabled={product.countInStock === 0}> */}
                <Button className='col-12' type='button' disabled={product.countInStock === 0}>
                {/* btn-block is deprecated: https://stackoverflow.com/a/65539232 | Use col-12 instead */}
                  Add To Cart
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default ProductScreen